import logo from './logo.svg';
import './App.css';
import GamePage from './GamePage';

function App() {
  return (
    <div className="App" style={{background: '#424242', margin: '0'}}>
      <GamePage />
    </div>
  );
}

export default App;
